@import "../../Sass/main";

.CompForgotPassword {
  align-items: center;
  background: $color121-light;
  color: $light;
  display: flex;
  flex-direction: column;
  font-family: $font-login;
  justify-content: center;
  min-height: 100vh;
  padding: min(5%) 10px min(5%) 10px;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    #triangulo-para-baixo {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid $light;
      height: 0;
      margin-top: 10px;
      width: 0;
    }

    .btn-area {
      text-align: center;
      width: 70%;
    }

    .message {
      color: $light;
      font-size: 1rem;
      text-align: center;
    }

    .title-message {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .buttonRedirect {
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      margin-top: 15px;
    }

    .buttonRedirectLarge {
      font-size: 14px;
    }

    .buttonSignin {
      background-color: $light;
      border-radius: 30px;
      border: none;
      color: $color121-light;
      font-family: $font-login-bold;
      margin-top: 10px;
      padding: 10px;
      text-align: center;
      width: 100%;
    }

    .buttonHover,
    .buttonHover:hover {
      cursor: pointer;
    }

    .errorMessage {
      color: red;
      font-size: 12px;
      margin: 20px 0 0 0;
    }

    .hazoImage {
      height: 200px;
      margin-top: -50px;
      width: 200px;
    }

    .hazotext {
      color: $light;
      font-family: $font-logo-hazo;
      font-size: 30px;
      margin: -50px 0 50px 0;
    }

    .inputArea {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 15px;
      width: 100%;

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        img {
          cursor: pointer;
          margin-left: -20px;
          margin-top: 10px;
          width: 20px;
        }

        input,
        input:focus-visible {
          width: calc(100% + 20px);
        }
      }

      label {
        font-family: $font-login-bold;
        font-size: 12px;
        height: 15px;
        margin: 0;
        margin-bottom: 5px;
      }

      input {
        background-color: #ffffff00;
        border: none;
        border-bottom: 1px solid $light;
        border-radius: 0;
        color: $light;
        font-size: 14px;
        height: 31px;
        outline-width: 0;
        width: calc(100%);
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -moz-box-shadow: 0 0 0 30px $color121-light inset !important;
        -webkit-appearance: none;
        -webkit-box-shadow: 0 0 0 30px $color121-light inset !important;
        -webkit-text-fill-color: $light !important;
        box-shadow: 0 0 0 30px $color121-light inset !important;
      }
    }

    .textArea {
      text-align: center;
    }

    .title {
      color: $light;
      font-size: 18px;
      margin: -30px 0 30px 0;
    }
  }
}

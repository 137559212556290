@import "../../../Sass/main";
@import "../style.scss";

.compDisclaimerInitial {
  display: flex;
  flex-direction: row;
  padding-left: calc(10% - 50px);

  .questionArea {
    width: calc(90% - 10px);
  }

  textarea {
    @include input-hazo();
    height: 84px;
  }

  .editAreaDI {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;

    button {
      background-color: $color121-light !important;
      border: none;
      border-radius: 5px;
      height: 33px;
      margin-left: 10px;
      padding: 0;
      width: 33px;

      img {
        height: 33px;
        width: 33px;
      }
    }

    div {
      align-items: center;
      background-color: $gray-default;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      height: 33px;
      justify-content: center;
      width: 33px;

      img {
        height: 22px;
        width: 22px;
      }
    }
  }
}

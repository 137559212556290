@import "../../Sass/main.scss";

.container-menu {
  background: $gray-light;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  gap: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  width: 100%;
  z-index: 1;

  .content-menu {
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;

    .btn-section {
      flex: 1;
      height: 45px;

      .active {
        background: #fff;
      }

      button {
        align-items: center;
        border: 1px solid $gray-light;
        border-bottom: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: $color121-dark-border;
        display: inline-flex;
        font-size: 14px;
        justify-content: center;
        height: 100%;
        width: 150px;

        &:hover {
          background: #ffffff7c;
          height: 100%;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .btn-share {
      align-items: center;
      border: none;
      display: flex;
      justify-content: center;
      color: $color121-dark-border;

      svg {
        font-size: 23px;
      }
    }

    @media (min-width: 1100px) {
      width: 60%;
    }

    @media (min-width: 560px) {
      .btn-section {
        button {
          width: 200px;
        }
      }
    }
  }
}

@import "../../Sass/main";

.compButtonOnOff {
  cursor: pointer;

  .contentAreaOn {
    background-color: $color121-light;
  }

  .contentAreaOff {
    background-color: $gray-dark;
  }

  .contentArea {
    align-items: center;
    border-radius: 20px;
    display: flex;
    height: 24px;
    width: 65px;

    .buttonText {
      color: $light;
      font-weight: bold;
      text-decoration: none;
    }

    .buttonTextOn {
      margin-left: 8px;
    }

    .buttonTextOff {
      margin-left: 33px;
    }
  }

  .circleWhite {
    background-color: $light;
    border-radius: 50%;
    height: 28px;
    margin-top: -26px;
    position: absolute;
    width: 28px;
  }
}

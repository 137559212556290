@font-face {
  font-family: "BebasNeue";
  src: url("../../Assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../../Assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: url("../../Assets/fonts/Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "Azonix";
  src: url("../../Assets/fonts/Azonix.otf") format("truetype");
}

@font-face {
  font-family: "AxiformaBold";
  src: url("../../Assets/fonts/Axiforma/AxiformaBold.otf") format("truetype");
}

@font-face {
  font-family: "AxiformaRegular";
  src: url("../../Assets/fonts/Axiforma/AxiformaRegular.otf") format("truetype");
}

@font-face {
  font-family: "GothamBold";
  src: url("../../Assets/fonts/Gotham-Font/GothamBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-BlackItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-BoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../../Assets/fonts/Montserrat/Montserrat-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("../../Assets/fonts/Montserrat/Montserrat-ExtraLight.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Light.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-LightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-MediumItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../Assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("../../Assets/fonts/Montserrat/Montserrat-Thin.ttf")
    format("truetype");
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("../../Assets/fonts/Montserrat/Montserrat-ThinItalic.ttf")
    format("truetype");
}

$font-121-bold: "GothamBold", sans-serif;
$font-login: "AxiformaRegular", sans-serif;
$font-login-bold: "AxiformaBold", sans-serif;
$font-logo-hazo: "Azonix", sans-serif;
$font-default: "BebasNeue", sans-serif;
$font-roboto: "Roboto", sans-serif;
$font-helvetica: "Helvetica", sans-serif;
$text-bold: 700;

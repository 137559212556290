@import "../../../Sass/main";

.compMenuInteractive {
  .buttonSelectedType {
    background-color: $color121-light;
    border: none;
    border-radius: 5px;
    color: $light;
    font-size: 14px;
    font-weight: bold;
    height: 43px;
    min-width: 50px;
    padding: 0 12px;

    &:hover {
      color: $light;
    }
  }

  .buttonNotSelectedType {
    border: 2px solid $gray-dark-less;
    border-radius: 5px;
    color: $gray-dark-less !important;
    font-size: 14px;
    font-weight: bold;
    height: 43px;
    min-width: 50px;
    padding: 0 12px;

    &:hover {
      color: $light;
    }
  }

  .iconChange {
    height: 15px;
    width: 15px;
  }

  .lineOption {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .optionsMenuInteractive {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      gap: 10px;
      margin-bottom: 10px;

      .optionsInput {
        border: none;
        border-radius: 5px;
        font-size: 14px;
        height: auto;
        min-width: 120px;
        overflow: hidden;
        padding: 10px 10px;
        resize: none;
        text-align: start;

        &::placeholder {
          color: $light;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $light;
        }

        &::-ms-input-placeholder {
          color: $light;
        }
      }
    }
  }
}

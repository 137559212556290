@import "../../Sass/main.scss";

.hide-comments {
  background: #fff;
  border-radius: 5px;
  border: none;
  color: $color121-light;
  margin: 0 auto;
  text-decoration: underline;
  width: 90%;
}

.comment-container {
  align-self: stretch;
  background: $gray-light;
  border-radius: 5px;
  margin: 10px auto;
  width: 90%;

  .comment-area {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;

    & > h4 {
      font-size: 16px;
      letter-spacing: 1px;
      padding: 0 5px;
    }

    .textarea {
      align-items: center;
      border: 1px solid $gray-default;
      border-radius: 5px;
      display: inline-flex;
      justify-content: center;
      min-height: 50px;
      max-height: 80px;

      textarea {
        align-content: center;
        background: none;
        border: none;
        display: flex;
        flex-grow: 1;
        font-size: 14px;
        flex-wrap: wrap;
        height: 100%;
        outline: none;
        padding: 3px;
        resize: none;
      }

      button {
        background: $color121-light;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        margin-right: 5px;
        padding: 5px 10px;
      }
    }
  }

  .ul-comment {
    margin: 0;
    overflow: hidden;
    padding: 0 10px;
    width: 100%;

    .li-comment {
      align-items: center;
      border-bottom: 1px solid #fff;
      display: flex;
      gap: 5px;
      padding: 10px 0;

      & > span {
        font-size: 14px;
        overflow-x: auto;
      }

      .btn-li {
        display: flex;
        img {
          width: 20px;
        }
      }

      & button {
        background: $gray-default;
        border: none;
        border-radius: 5px;
        display: flex;
        margin: 0 2px;
        padding: 5px;
      }

      .date {
        color: $gray-dark-less;
        font-size: 10px;
        width: fit-content;
      }

      .text {
        flex: 1;
      }
    }
  }
}

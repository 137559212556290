@import "../../../Sass/main";

@media only screen and (max-width: 667px) {
  .optionsConditions {
    align-items: flex-start !important;
    flex-direction: column;
  }

  .goToCompNoneQuestion {
    flex-direction: column;
  }

  .goToComp {
    flex-direction: column;
  }
}

.optionNoneQuestion {
  display: flex;
  font-size: 12px;
  gap: 10px;
  margin-top: 10px;
}

.multipleOptionsBox {
  border-style: dashed;
  border-width: 0.5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  margin: 10px 0px;
  width: fit-content;
}

.optionOtherOption {
  display: flex;
  font-size: 12px;
  gap: 10px;
  margin-top: 10px;
}

.goToCompNoneQuestion {
  display: flex;
  width: fit-content;
}

.goToComp {
  align-items: center;
  display: flex;
  width: fit-content;
}

.optionsConditions {
  align-items: center;
  display: flex;
  gap: 10px;
}

.conditionsArea {
  .option {
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    min-height: 43px;
    min-width: 120px;
    padding: 10px 10px;
  }
}

.configurationOption {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@import "../../Sass/main";

.projectsComp {
  background-color: $color121-light;
  min-height: calc(100vh + 10px);

  @media (min-width: 1100px) {
    .contentArea {
      margin: 0 0 0 20%;
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    .contentArea {
      margin: 0;
      width: 100%;
    }
  }

  .contentArea {
    background-color: $light;
    min-height: calc(100vh);

    .apresentationArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 0 0 -20px;
      width: 100%;

      .arrow {
        margin-left: -30px;

        img {
          height: 15px;
          margin-right: 10px;
          width: 15px;
        }
      }

      .hazoImg {
        height: 128px;
        width: 128px;
      }

      .text {
        color: $color121-light;
        font-size: 1.2rem;
      }
    }

    .projectsArea {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-end;
      margin-top: 20px;
      padding-bottom: 20px;

      .filterArea {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;

        .selectFilter {
          background-color: $color121-light;
          border: none;
          border-radius: 15px;
          color: $light;
          cursor: pointer;
          height: 40px;
          margin: 0 15px;
          padding: 10px;
          width: 180px;

          .triangleDown {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $color121-light;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        }
      }

      .newProjectButton {
        align-items: center;
        background-color: $color121-light;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 80px;
        justify-content: center;
        margin: 0 15px;
        padding: 10px;
        width: calc(100% - 30px);

        .hazo {
          height: 60px;
          width: 60px;
        }

        .line {
          border-left: 2px solid $light;
          height: 60px;
          width: 2px;
        }

        .plusArea {
          width: 80px;

          .plus {
            height: 50px;
            width: 50px;
          }
        }

        .textButton {
          color: $light;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

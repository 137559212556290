@import "../../../Sass/main.scss";

.compCloseAnswer,
.compMultipleChoice {
  color: $gray-dark;
  display: flex;
  flex-direction: row;
  padding-left: calc(10% - 50px);

  .questionArea {
    width: calc(90% - 10px);
  }

  textarea {
    @include input-hazo();
    height: 108px;
  }

  input {
    @include input-hazo();
  }
}

.conditionsArea {
  span {
    font-size: 12px;
    margin-top: -10px;
  }

  img {
    height: 20px;
    margin: 0 1px;
    width: 20px;
  }
}

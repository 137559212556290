@import "../../Sass/main";

body {
  overflow: visible; /* Hide scrollbars */
}

.compReports {
  background-color: #e7e7e7;

  @media (min-width: 1100px) {
    .container_message {
      margin: 0 0 0 20%;
      padding: 10px 10px 0 10px;
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    .container_message {
      margin: 0 0 0 0;
      padding: 10px 10px 0 10px;
      width: 100%;
    }
  }

  .content {
    min-height: 100vh;

    .areaReports {
      align-items: center;
      display: flex;
      flex-direction: column;

      .table {
        width: 90%;

        tbody {
          td {
            word-break: break-all;
          }
        }

        thead {
          td {
            div {
              align-items: center;
              display: flex;
              flex-direction: row;
            }
          }
        }

        thead:first-child {
          font-weight: bold;
          text-decoration: underline;
        }

        td:last-child {
          text-align: center;
          width: 85px;
        }

        td:first-child + td {
          text-align: center;
          width: 80px;
        }
      }

      .buttonShowTable {
        align-items: center;
        background-color: $color121-light;
        border-radius: 10px;
        border: none;
        color: $light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: center;
        padding: 6px 12px;
      }
    }

    .filterArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin-bottom: 20px;

      button {
        border: none;
        border-radius: 5px;
        color: $light;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        height: 30px;
        padding: 0 12px;
      }

      .buttonBlue {
        background-color: $color121-light;
      }

      .buttonGray {
        background-color: $gray-default;
      }

      img {
        height: 30px;
        width: 30px;
      }

      .inputDateArea {
        background-color: $light;
        border-radius: 20px;
        padding: 6px;

        input {
          border: none;
          font-size: 14px;
          margin-left: 5px;
          vertical-align: center;
          width: 105px;
        }

        input::-webkit-calendar-picker-indicator {
          margin: 0px;
          padding: 0px;
          width: 20px;
        }
      }
    }

    .projectInformations {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      margin: 10px 0 20px 0;

      .buttonGeratePdf {
        align-items: center;
        background-color: $color121-light;
        border: none;
        border-radius: 5px;
        color: $light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: center;
        padding: 6px;
        text-align: center;
        width: 100px;
      }

      .exportArea {
        align-items: center;
        background-color: $color121-light;
        border: none;
        border-radius: 5px;
        color: $light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: center;
        padding: 6px;
        text-align: center;
        width: 74px;

        .exportButton {
          align-items: center;
          background-color: $color121-light;
          border: none;
          color: $light;
          cursor: pointer;
          display: flex;
          font-weight: bold;
          justify-content: center;
          padding: 0;

          img {
            width: 50px;
          }
        }
      }

      .hazoImage {
        height: 50px;
        width: 50px;
      }

      .informations {
        align-items: center;
        background-color: $color121-light;
        border-radius: 5px;
        color: $light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 6px;

        img {
          width: 30px;
        }

        .mainText {
          font-size: 22px;
          font-weight: bold;
        }

        .secondaryText {
          font-size: 12px;
        }
      }

      .informationsStatus {
        align-items: center;
        background-color: $color121-light;
        border-radius: 5px;
        color: $light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6px;
        text-align: center;
        width: 228px;

        .areaCloseProject {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 5px;

          p {
            margin: 0;
          }

          .areaButtonArea {
            align-items: center;
            background-color: $gray-light;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            padding: 6px;
          }

          .secondaryText {
            font-size: 12px;
          }

          .primaryText {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .informationsGray {
        background-color: $gray-dark;
      }
    }
  }
}

@import "../../Sass/main";

.compConfigurationQuestionButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  right: 10px;

  button {
    @include button-121-primary();
    border-radius: 5px;
    height: 33px;
    padding: 6px 12px;
    margin-right: 5px;
  }

  .buttonNew {
    background-color: $color121-light !important;
  }

  .editArea {
    align-items: center;
    background-color: $gray-default;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 33px;
    justify-content: center;
    margin-left: 10px;
    padding: 0 5px;
    width: 33px;

    img {
      height: 22px;
      width: 22px;
    }
  }
}

.compConfigurationQuestionButtonsWarningArea {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 10px 0;

    button {
      @include button-121-secondary();
      border-radius: 5px;
      height: 33px;
      padding: 6px 12px;
      margin-right: 5px;
    }

    .buttonCancel {
      color: $gray-dark !important;
    }

    .buttonWarning,
    .buttonWarning:hover {
      background-color: $danger-color !important;
      color: $light;
    }
  }
}

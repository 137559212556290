@import "../../Sass/main";

.redBackground {
  background-color: red !important;
  font-weight: bold;
  .arrow::before,
  .arrow {
    border-bottom-color: red !important;
    border-top-color: red !important;
  }
}

.redBackgroundNotification {
  background-color: $danger-color !important;
  font-weight: bold;
  font-weight: bold;
}

.tooltipZero {
  z-index: 0 !important;
}

.boxEdit {
  margin-bottom: 30px;
}

.boxNoEdit {
  margin-bottom: 20px;
}

.buttonSelectAnswer {
  background-color: $color121-light;
  border: none;
  border-radius: 5px;
  color: $light;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 43px;
  margin-top: 10px;
  padding: 6px 12px;

  .polygon {
    height: 10px;
    margin-left: 5px;
    width: 10px;
  }
}

.buttonPresets {
  background-color: $color121-light;
  border: none;
  border-radius: 5px;
  color: $light;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  padding: 0 12px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.buttonPresetInputPadding {
  padding-right: 84px !important;
}

.buttonAddOption {
  @include button-121-primary();
  border-radius: 5px;
  font-weight: bold;
  height: 43px;
  padding: 6px 12px;
  margin: 13px 0 0 0;
}

.dropdownSelectQuestion {
  background-color: $gray-default;
  border: none;
  border-radius: 5px;
  color: $light;
  display: 1;
  font-size: 14px;
  height: 43px;
  justify-content: center;
  text-align: center;
  text-align-last: center;
  width: 150px;
}

.editGroup {
  align-items: center;
  background-color: $gray-default;
  border-radius: 5px;
  display: flex;
  height: 43px;
  justify-content: space-around;
  padding: 0 5px;
  width: 43px;

  div {
    background-color: $gray-dark-less;
    height: 26px;
    width: 1px;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    border-radius: 50%;
    height: 20px;
    padding: 0;
    width: 20px;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
  }

  img {
    height: 25px;
    width: 25px;
  }
}

.editOnlyColor {
  align-items: center;
  background-color: $gray-default;
  border-radius: 5px;
  display: flex;
  height: 43px;
  justify-content: space-between;
  padding-left: 9.5px;
  width: 86px;

  img {
    height: 25px;
    width: 25px;
  }

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    border-radius: 0 5px 5px 0;
    height: 43px;
    padding: 0;
    width: 43px;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 0;
  }
}

.iconEdit {
  align-items: center;
  background-color: $gray-default;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 43px;
  justify-content: center;
  margin-top: 5px;
  position: absolute;
  right: 10px;
  width: 43px;

  img {
    height: 25px;
    width: 25px;
  }
}

.inputTitleError {
  border: 1px solid red;
}

.inputTitle {
  background-color: $gray-light;
  border-radius: 5px;
  color: $gray-dark;
  margin-left: 10px;
  padding: 10px 10px 10px 10px;
  position: relative;
  width: calc(100% - 10px);
  word-wrap: break-word;
}

.inputUrl {
  margin-top: 10px;
}

.irParaMargin {
  margin-left: 10px;
}

.gap {
  gap: 10px;
}

.options {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 10px;
  margin-top: 15px;
  word-break: break-word;

  .optionsInput {
    border: none;
    border-radius: 5px;
    font-size: 14px;
    height: auto;
    min-width: 120px;
    overflow: hidden;
    padding: 10px 10px;
    resize: none;
    text-align: start;

    &::placeholder {
      color: $light;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $light;
    }

    &::-ms-input-placeholder {
      color: $light;
    }
  }
}

.robotIcon {
  border-radius: 50%;
  height: 50px !important;
  margin-top: 21px;
  width: 50px !important;
}

.showOptionsArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.showOptionsButton {
  align-items: center;
  background-color: $buttonChatbotDefaultColor;
  border: none;
  border-radius: 5px;
  color: $light;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  min-height: 43px;
  min-width: 43px;
  padding: 6px 12px;
  text-align: center;
  word-break: break-all;
}

.title {
  color: $gray-dark;
  font-size: 14px;
  margin-left: 10px;
}

hr {
  margin: 16px 0 !important;
}

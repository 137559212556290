@import "../../../Sass/main";

.compModalWarning {
  .modal-header {
    border: none !important;
    padding: 10px 10px 0 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 10px 10px 10px;
      text-align: center;

      button {
        align-items: center;
        background-color: $light;
        border: none;
        border-radius: 10px;
        color: $light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: center;
        min-width: 120px;
        padding: 6px 12px;
      }
    }
  }
}

@import "../../../Sass/main";

.ModalMenuProjects {
  .modal-header {
    border: none !important;
    padding: 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 30px;

      .projectTitle {
        align-items: center;
        display: flex;
        font-size: 25px;
        justify-content: center;
        margin: 5px 0;
        padding: 0 5%;

        input {
          border: none;
          border-radius: 5px;
          font-size: 16px;
        }

        .projectTitleIcon {
          cursor: pointer;
          height: 25px;
          margin-left: 10px;
        }
      }

      .hrTitle {
        background-color: white;
        margin: 0 !important;
        width: 90%;
      }

      .menuRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }

      .boxPresets,
      .boxPresets:hover {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
        height: 100px;
        justify-content: flex-start;
        margin-top: 10px;
        text-decoration: none;

        img {
          height: 40px;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 40px;
        }

        .pTitle {
          color: $light;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 0;
          text-align: center;
        }

        .pSubtitle {
          font-size: 12px;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}

@import "../../Sass/main";

.compAccount {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .content {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    width: 500px;

    #triangulo-para-baixo {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid $light;
      height: 0;
      margin-top: 10px;
      width: 0;
    }

    .hazoImage {
      margin: -40px 0;
      width: 200px;
    }

    .hazotext {
      color: $light;
      font-family: $font-logo-hazo;
      font-size: 30px;
    }

    .question {
      color: $light;
      font-family: $font-login;
      margin-bottom: 10px;
    }

    .title {
      color: $light;
      font-family: $font-login;
      font-size: 18px;
      font-weight: bold;
    }

    .icon {
      align-self: start;
      display: flex;

      .btn-icon {
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        margin-bottom: 10px;
        padding: 8px;
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }

    .planArea {
      align-items: center;
      border: solid 1px $light;
      border-radius: 5px;
      color: $light;
      display: flex;
      flex-direction: column;
      font-family: $font-login;
      justify-content: center;
      margin-bottom: 20px;
      min-width: 300px;
      padding: 10px;

      .planTitle {
        font-size: 18px;
        font-weight: bold;
      }

      .planEmail {
        font-size: 15px;
      }

      p {
        margin: 0;
      }
    }

    .button {
      background-color: $light;
      border: 2px solid $light;
      border-radius: 50px;
      color: $color121-light;
      cursor: pointer;
      font-weight: bold;
      margin: 5px 0;
      padding: 6px 12px;
      width: 200px;
    }

    .onlyUnderline {
      align-items: center;
      background: none;
      border: none;
      color: $light;
      cursor: pointer;
      display: flex;
      font-family: $font-login;
      height: 40px;
      justify-content: center;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 720px) {
    .content {
      width: 90%;

      .icon {
        margin: 5px;
      }
    }
  }
}

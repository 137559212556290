@import "../abstracts/variables";
@import "../base/typography";

@mixin button($w, $h, $bg: $color121, $color: $light) {
  background: $bg;
  border: none;
  border-radius: 4px;
  color: $color;
  cursor: pointer;
  font-size: 1rem;
  height: $h;
  padding: 0.3rem;
  transition: ease-in-out 0.3s;
  width: $w;

  &:hover {
    background: darken($bg, 15%);
    color: darken($light, 25%);
  }
}

@mixin button-121-primary() {
  background-color: $color121-light !important;
  border: none;
  color: $light;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  min-width: 90px;

  &:hover {
    color: $light;
  }
}

@mixin button-121-secondary() {
  background-color: $gray-default !important;
  border: none;
  color: $gray-dark;
  font-size: 14px;
  margin-left: 10px;
  min-width: 90px;

  &:hover {
    color: $gray-dark;
  }
}

@import "../../Sass/main";

.chatbotHeader {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  justify-content: center;
  height: 65px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  .containerWidth {
    background-color: #609ef1;
    height: 65px;
    width: 100%;
  }

  .containerHeaderMain {
    display: flex;
    margin: 0 15px;
    width: 60%;

    .imageAndName {
      display: flex;
      flex: 100px 1;
    }

    .titleBody {
      align-content: center;
      align-items: flex-start;
      color: $light;
      display: flex;
      flex-direction: column;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      justify-content: center;
      letter-spacing: 1.1px;
      margin-left: 10px;
      width: 100%;
    }

    .titleHeaderMain {
      font-size: 15px;
      line-height: 1.42857143;
      margin: 0;
    }

    .titleHeaderMainSubtitle {
      font-size: 12px;
      margin: 0;
    }

    .logoArea {
      align-items: center;
      display: flex;
      max-height: 50px;
      max-width: 100px;

      .logo {
        height: 100% !important;
        object-fit: contain;
        width: 100% !important;
      }
    }

    .editImg {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }

    .divDropDown {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      height: auto;
      position: relative;
      right: 10px;
      z-index: 2;
    }

    .buttonDropDown {
      align-items: center;
      background: none;
      border: none;
      color: $light;
      display: flex;
      height: 40px;
      justify-content: center;
      position: relative;
      width: 40px;

      &:hover,
      &:focus,
      &:focus-visible {
        transform: scale(1.1);
      }
    }

    svg {
      font-size: 1.3rem;
      filter: drop-shadow(0px 0px 0.5px rgba(0, 0, 0, 5));
    }

    .dropDown {
      align-items: center;
      background: $light;
      border-radius: 10px;
      box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px 5px;
      position: absolute;
      right: -5px;
      top: 55px;
      min-width: 100%;
      z-index: 10;

      .small-pointer {
        background: $light;
        border-radius: 2px;
        height: 25px;
        position: absolute;
        right: 12px;
        transform: rotate(45deg);
        top: -10px;
        width: 25px;
      }
    }

    .buttonLayout {
      background: $light;
      border: 1px solid $gray-default;
      border-radius: 5px;
      color: $gray-dark;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      height: 38px;
      letter-spacing: 1.1px;
      padding: 8px;
      position: relative;
      text-align: center;
      white-space: nowrap;
      width: 100%;
    }

    @media (max-width: 1100px) {
      display: flex;
      margin: 0 10px;
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    .containerWidth {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px 0;
      width: 100%;
    }
  }

  @media (max-width: 1100px) {
    .containerWidth {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px 0;
      width: 100%;
    }
  }

  @media (min-width: 570px) {
    .containerHeaderMain {
      .bigScreen {
        background: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
        width: auto;
      }

      .buttonLayout {
        border-width: 2px;
      }
    }
  }
}

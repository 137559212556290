@import "../../Sass/main";

.compManagerPlan {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .content {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    width: 500px;

    hr {
      border: 0;
      border-top: 1px solid $light;
      margin: 0;
      width: 100%;
    }

    .plan-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-family: $font-login;

      .button {
        background-color: $light;
        border: 2px solid $light;
        border-radius: 50px;
        color: $color121-light;
        cursor: pointer;
        font-weight: bold;
        padding: 6px 12px;
        margin: 5px 0;
        width: 200px;
      }

      .onlyUnderline {
        align-items: center;
        background: none;
        border: none;
        color: $light;
        cursor: pointer;
        display: flex;
        font-family: $font-login;
        justify-content: center;
        height: 40px;
        text-decoration: underline;
      }

      .planArea {
        border: solid 1px $light;
        border-radius: 5px;
        margin: 10px;
        min-width: 256px;
        padding: 10px;

        .descritionArea {
          align-items: center;
          color: $light;
          display: flex;
          font-family: $font-login;
          gap: 15px;
        }
      }

      .planTitle {
        color: $light;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
    }

    .hazoImage {
      margin: -40px 0;
      width: 200px;
    }

    .hazotext {
      color: $light;
      font-family: $font-logo-hazo;
      font-size: 30px;
    }
  }

  .onlyUnderline {
    align-items: center;
    background: none;
    border: none;
    color: $light;
    cursor: pointer;
    display: flex;
    font-family: $font-login;
    justify-content: center;
    padding: 10px 0;
    text-decoration: underline;
  }

  .icon {
    align-self: start;
    display: flex;

    .btn-icon {
      align-items: center;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      margin-bottom: 10px;
      padding: 8px;
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .interactionsArea {
    margin-top: 10px;

    .interactions {
      color: $light;
      font-family: $font-login;
      margin: 0;
    }
  }

  @media screen and (max-width: 720px) {
    .content {
      width: 90%;

      .icon {
        margin: 5px;
      }
    }
  }
}

@import "../../../Sass/main";

.compModalPresets {
  .modal-header {
    border: none !important;
    padding: 10px 10px 0 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      padding: 10px;
      position: relative;

      .containerPreset {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5px;
        width: 100%;
      }

      .presetContent {
        align-items: center;
        border-top: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        padding: 15px 0px;
        width: 100%;
      }

      h3 {
        font-size: 18px;
        margin: 0;
      }

      .boxPresets {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: center;
        padding: 0px;
        width: 100%;

        .preset {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 5px;
          justify-content: space-between;
          padding: 0px;
          width: 100%;
        }

        img {
          height: 45px;
          width: 45px;
        }

        .pTitle {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          margin: 0;
        }

        .pSubtitle {
          font-size: 12px;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}

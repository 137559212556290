@import "../../../Sass/main";

.compModalCropImage {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 1051;

  .crop-container {
    bottom: 0;
    height: calc(100vh - 100px);
    left: 0;
    position: absolute;
    right: 0;
    top: 100px;
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #3f51b5;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    height: 2px;
    width: 100%;
  }

  .zoom-range::-moz-range-thumb {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 50%;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 12px;
  }

  .zoom-range::-webkit-slider-thumb {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 50%;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 12px;
  }

  .controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }

  .controlsArea {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .controls {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    left: 0;
    width: 50%;

    .buttonArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;

      .buttonSave {
        background-color: $light;
        border: 1px solid $light;
        border-radius: 10px;
        border-radius: 5px;
        color: $color121-light;
        cursor: pointer;
        font-weight: bold;
        height: 33px;
        width: 90px;
      }

      .buttonCancel {
        background-color: $gray-default;
        border: 1px solid $gray-default;
        border-radius: 5px;
        color: $color121-light;
        cursor: pointer;
        font-weight: bold;
        height: 33px;
        width: 90px;
      }
    }

    .zoom-range {
      -moz-appearance: none;
      -webkit-appearance: none;
      border-top: 5px solid #fff;
      border-bottom: 5px solid #fff;
      background: #3f51b5;
      height: 2px;
      width: 100%;
    }

    .zoom-range::-moz-range-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #3f51b5;
      border: 1px solid #3f51b5;
      border-radius: 50%;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 12px;
    }

    .zoom-range::-webkit-slider-thumb {
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 1px solid #3f51b5;
      background: #3f51b5;
      border-radius: 50%;
      height: 12px;
      transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 12px;
    }
  }

  .controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
}

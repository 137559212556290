@import "../../../Sass/main.scss";

.likeDislikeIconEditOff {
  height: 43px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 43px;
}

.likeDislikeIcon {
  height: 43px;
  width: 43px;
}

.showOptionsButton {
  align-items: center;
  background-color: $buttonChatbotDefaultColor;
  border: none;
  border-radius: 5px;
  color: $light;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  min-height: 43px;
  min-width: 43px;
  padding: 6px 12px;
  text-align: center;

  img {
    height: 20px;
    margin: 2px;
    object-fit: contain;
    width: 20px;
  }
}

.questionArea {
  width: calc(90% - 10px);
}

.imageContainer {
  max-width: 425px;
  max-height: 425px;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.videoContainer {
  max-width: 425px;
  max-height: 425px;
  overflow: hidden;
}

@import "../../Sass/main";

.compNavigationHeader {
  align-items: center;
  background-color: $gray-light;
  display: flex;
  height: 55px;
  gap: 10px;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  width: 100%;
  z-index: 1;

  .iconNavigation {
    cursor: pointer;
    height: 45px;
  }

  #triangle-to-right {
    border-bottom: 16px solid transparent;
    border-left: 20px solid #bebebe;
    border-top: 16px solid transparent;
    height: 0;
    width: 0;
  }
}

.warningHazo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;

  img {
    margin: -20px 0;
  }
}

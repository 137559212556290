@import "../../../Sass/main";

.cardStyled {
  background-color: $color121-light;
  border-radius: 50px;
  color: $light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 544px;
  padding: 10px;

  .card-info {
    padding: 20px 0;

    .card-header {
      background: none;
      border: none;
      text-align: center;

      .card-title {
        color: $light;
        font-size: 42px;
      }

      .month-price {
        font-size: 28px;
        font-weight: 400;
      }

      .card-price {
        color: $light;
        font-size: 38px;
        padding-top: 25px;
      }

      p {
        font-size: 14px;
      }

      span {
        font-size: 18px;
      }

      & > span {
        font-size: 28px;
      }
    }

    .card-body {
      margin: 0 auto;
      max-width: 80%;

      .text-enterprise {
        font-size: 1rem;
        text-align: center;
      }

      & > ul {
        padding: 20px;
      }
    }
  }

  .select-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;

    & > span {
      font-size: 14px;
    }

    .select-plan {
      border-radius: 10px;
      flex: 1;
      margin: 15px 0;
      outline: none;
      padding: 5px;
    }
  }

  .button-con {
    padding: 0 15px;
    text-align: center;

    .display-none {
      display: none;
    }

    button {
      background: transparent;
      border: 3px solid $light;
      border-radius: 20px;
      color: $light;
      cursor: pointer;
      font-size: inherit;
      outline: none;
      padding: 0.8rem 1.8rem;

      &:disabled {
        border: 3px solid $gray-default;
        color: $gray-default;
        cursor: not-allowed;
      }
    }
  }

  .text-check {
    align-items: center;
    display: flex;
    padding: 0.4rem 0;

    svg {
      color: $light;
      margin-right: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  .free {
    color: $gray-default;

    svg {
      color: $gray-default;
    }
  }

  @media screen and (max-width: 767px) {
    .card-info {
      .card-body {
        max-width: 100%;
      }
    }
  }
}

.chartCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  position: relative;
  width: 100%;

  .chartBox {
    align-self: flex-start;
    height: 250px;
    position: relative;
    width: 250px;

    & > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      font-weight: 700;
      justify-content: center;
      margin: 2px;
      text-align: center;
      width: 100px;

      p {
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 13px;
        margin-bottom: 4px;
        word-spacing: 2px;
      }

      span {
        font-size: 9px;
      }
    }

    .negativeToZero {
      color: #ff2e2c;
      left: -15px;
      position: absolute;
      top: 32px;
      width: 120px;
    }

    .zeroTo50 {
      color: #ffce56;
      position: absolute;
      right: -20px;
      top: 30px;
    }

    .fiveTo75 {
      color: #88c43e;
      position: absolute;
      right: -70px;
      top: 90px;
    }

    .sevenTo100 {
      color: #19a262;
      position: absolute;
      right: -65px;
      top: 150px;
      width: 60px;
    }
  }

  .gridBox {
    width: 100%;
  }

  .gridChart {
    color: #fff;
    display: grid;
    grid-template-rows: 1fr;
    height: 20px;
    width: 100%;

    & > div {
      align-items: center;
      display: flex;
      font-size: 11px;
      justify-content: center;
      overflow: hidden;
    }

    & > div:nth-of-type(2) {
      color: #000;
    }
  }

  .labelSection {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 15px 10px;
    width: 100%;

    & > div {
      font-size: 12px;
      line-height: 7px;
      padding: 10px;
      text-align: center;
    }

    .negative {
      color: #ff2e2c;
    }

    .neutral {
      color: #ffce56;
    }

    .positive {
      color: #19a262;
    }

    & span {
      font-size: 9px;
      font-weight: 600;
    }
  }

  @media (min-width: 500px) {
    margin: 0 auto;
    width: 70%;

    .chartBox {
      margin: 0 auto;
    }

    .labelSection {
      justify-content: space-around;
    }
  }
}

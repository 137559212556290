@import "../../Sass/main";

.HeaderResults {
  align-items: center;
  background: $color121-light;
  display: flex;
  height: 65px;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  .containerWidth {
    background: $color121-light;
    position: -webkit-sticky;
    position: sticky;
  }

  @media (min-width: 1100px) {
    .containerWidth {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px 0;
      width: 100%;
    }
  }

  @media (max-width: 1100px) {
    .containerWidth {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px 0;
      width: 100%;
    }
  }

  .containerHeaderMain {
    display: flex;
    margin: 0 10px;
    width: 60%;

    .contentHeader {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;

      .logoArea {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 50px;

        .logo {
          max-height: 50px !important;
          max-width: 50px !important;
        }
      }

      .titleBody {
        color: $light;
        display: flex;
        flex-direction: column;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        justify-content: center;
        margin: 4px;
        text-align: center;
        width: 100%;

        .titleHeaderMain {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.42857143;
          margin: 0;
        }

        .titleHeaderMainSubtitle {
          font-size: 12px;
          margin: 0;
        }
      }

      h1 {
        font-size: 1rem;
        margin: 0;
      }
    }

    @media (max-width: 1100px) {
      display: flex;
      margin: 0 10px;
      width: 100%;
    }
  }
}

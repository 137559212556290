@import "../../Sass/main.scss";

.container-data {
  background-color: #e7e7e7;
  padding: 10px;
  width: 100%;

  .wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .warning {
      align-items: center;
      background-color: $warning;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 5px 10px;

      .warning-content {
        align-items: center;
        color: $warning-text;
        display: flex;
        gap: 2px;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;

        p {
          margin: 0;
        }

        .icon {
          color: $warning-text;
          flex-shrink: 0;
        }
      }

      .link {
        text-decoration: underline;
      }
    }
  }

  .content {
    background-color: #fff;
    border-radius: 5px;
    min-height: 100vh;
    padding: 10px 10px 0 10px;
    overflow: hidden;
  }

  .content-without-plan {
    background-color: #fff;
    border-radius: 5px;
    min-height: 100vh;
    margin-top: 95px;
    padding: 10px 10px 0 10px;
    overflow: hidden;
  }

  .integration {
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;

    &.locked {
      cursor: pointer;
      opacity: 0.6;
      box-shadow: 1px 1px 5px #c4c4c4;
    }
  }

  .integration-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    width: 100%;

    .icon {
      flex-shrink: 0;
    }
  }

  .integration-texts {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 20px;

    h4,
    p {
      margin: 0;
      padding: 0;
    }
  }

  .integration-switch {
    display: flex;
    margin-left: auto;
    margin-right: 0;

    .icon {
      color: gray;
    }
  }

  .message {
    background: #e7e7e7;
    color: $danger-color;
    font-weight: 500;
    margin: 10px 0;
    padding: 5px 0;
    text-align: center;
  }

  .control-area {
    display: flex;
    margin: 15px 0;
    width: 100%;

    button {
      align-items: center;
      border: none;
      display: flex;
      justify-content: center;
    }

    .btn-edit,
    .btn-delete {
      background: $gray-default;
      border-radius: 5px;
      font-size: 23px;
    }

    .btn-delete {
      margin: 0 10px;
    }

    .btn-save {
      background: $gray-default;
      border-radius: 5px;
      margin-left: 10px;
      padding: 5px 15px;
    }

    input {
      flex: 1;
    }
  }

  .upgrade-plan {
    color: $warning-text;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }

  .table-area {
    margin: 0 auto;
    margin: 2px;
    min-width: 100%;
    overflow-y: scroll;

    table {
      border: 1px solid #ccc;
      min-width: 100%;

      thead {
        th {
          border: 1px solid $gray-default;
          margin: 10px;
          min-width: 200px;
          padding: 10px;
          text-align: center;
        }
      }

      tr {
        border: 1px solid $gray-default;
      }

      td {
        border: 1px solid $gray-default;
        min-width: 200px;
        padding: 10px;
        text-align: center;
      }
    }
  }
}

@import "../../Sass/main";

.compCancelPlan {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .content {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    width: 500px;

    p {
      color: #fff;
      font-size: 20px;
      margin: -30px 20px 0px 20px;
      text-align: justify;
    }

    .icon {
      align-self: start;
      display: flex;

      .btn-icon {
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        margin-bottom: 10px;
        padding: 8px;
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }

    .buttonArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
    }

    .errorMessage {
      color: red;
      margin: 20px;
    }

    .reasonArea {
      align-items: center;
      border: solid 1px $light;
      border-radius: 5px;
      color: $light;
      display: flex;
      flex-direction: column;
      font-family: $font-login;
      justify-content: center;
      margin: 20px;
      min-width: 256px;
      padding: 10px;
      width: 90%;

      .inputArea {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        padding: 10px 0;
        width: 100%;

        label {
          margin: 0;
        }

        &:first-child {
          padding-top: 0;
        }
      }

      textarea {
        border: none;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
      }
    }

    .redBackground {
      background-color: red !important;
      font-weight: bold;
      .arrow::before,
      .arrow {
        border-bottom-color: red !important;
        border-top-color: red !important;
      }
    }

    .onlyUnderline {
      align-items: center;
      background: none;
      border: none;
      color: $light;
      cursor: pointer;
      display: flex;
      font-family: $font-login;
      height: 40px;
      justify-content: center;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 720px) {
    .content {
      width: 90%;

      .icon {
        margin: 5px;
      }
    }
  }
}

@import "../../../Sass/main";

.testemodal {
  .modal-content {
    border: none !important;
    border-radius: 50px !important;
  }
}

.CompModalCustomLayout {
  background-color: $color121-light !important;
  border: none !important;
  border-radius: 50px !important;

  .areaColorHeader {
    align-items: center;
    background: $light-opacity;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
    padding: 6px 12px;
    width: 60%;

    .editColorHeader {
      align-items: center;
      background-color: $gray-default;
      border-radius: 5px;
      color: $light;
      display: flex;
      height: 40px;
      justify-content: space-between;
      width: 100px;

      img {
        height: 30px;
        margin-right: 10px;
        width: 30px;
      }

      .inputColorChange {
        -webkit-appearance: none;
        border: none;
        border-radius: 5px 0 0 5px;
        height: 40px;
        padding: 0;
        width: 50px;
      }

      .inputColorChange::-webkit-color-swatch-wrapper {
        padding: 0;
      }

      .inputColorChange::-webkit-color-swatch {
        border: none;
        border-radius: 5px 0 0 5px;
        padding: 0;
      }
    }
  }

  .imageArea {
    align-items: center;
    border: 1px solid $light;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    max-width: 90%;
    padding: 10px 0;

    @media screen and (min-width: 840px) {
      width: 70%;
    }

    img {
      border: 50%;
    }

    p {
      color: $light;
      font-weight: 500;
      margin: 0;
    }

    .inputText {
      background: $light;
      border: none;
      border: 2px solid $gray-default;
      color: #000;
      font-size: 13px;
      font-weight: bold;
      height: 38px;
      opacity: 0.8;
      outline: 0px;
      padding-left: 5px;
      text-align: center;
      width: 85%;

      &:hover,
      &:focus,
      &:focus-visible {
        opacity: 1;
        transform: scale(1.1);
      }
    }

    .inputHide {
      display: none;
    }

    .orientation {
      color: $light;
      font-size: 12px;
      text-align: center;
    }

    .error {
      color: $danger-color;
    }

    .robotAndHazoImage {
      height: 130px;
    }

    .labelLogoImg {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      width: 100%;
    }

    .imageChange {
      background: $light;
      border: 1px solid;
      border-radius: 10px;
      color: $color121-dark-border;
      font-weight: 700;
      margin-top: 10px;
      padding: 3px 8px;

      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }

    .radiusImg {
      border-radius: 50%;
    }

    & > input {
      border-radius: 4px;
      margin-top: 10px;
      padding: 9px;
    }
  }

  .buttonArea {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;

    & > button {
      background: $light;
      border: 1px solid $gray-default;
      border-radius: 5px;
      font-weight: 700;
      height: 33px;
      width: 90px;
    }
    .buttonSave {
      color: $color121-light;
    }

    .buttonCancel {
      background: $gray-default;
      color: $color121-dark;
    }
  }
}

@import "../../../Sass/main.scss";

.optionsAreaChoiseStars {
  display: flex;
  flex-wrap: wrap;
}

.optionsArea {
  .optionsStar {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    font-size: 12px;
    gap: 5px;
    margin-top: 15px;
    width: 150px !important;
  }

  img {
    height: 20px;
    margin: 0 1px;
    width: 20px;
  }

  .boxCheck {
    align-items: center;
    background-color: $light;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 6px;
    width: 30px;
  }

  .boxChecked {
    background-color: $color121-light;
    border-radius: 50%;
    height: 18px;
    width: 18px;
  }

  .options {
    display: flex;
    flex-direction: row;
  }

  .starsArea {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

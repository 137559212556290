@import "../../Sass/main.scss";

.compLogic {
  .questionArea {
    width: 100%;
  }

  .showOptionsArea {
    align-items: center;
    display: flex;
    flex-direction: column;

    .sectionOption {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0;

      svg {
        align-self: center;
        height: 33px;
        object-fit: contain;
        color: $color121;
      }

      .option {
        border: 2px solid $color121;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        width: 100%;

        & > div {
          margin: 5px;
        }
      }

      .show-next {
        align-items: center;
        align-self: center;
        background-color: $buttonChatbotDefaultColor;
        border: none;
        border-radius: 5px;
        color: $light;
        display: flex;
        justify-content: center;
        min-height: 43px;
        min-width: 43px;
        padding: 6px 12px;

        .next {
          max-width: 500px;
        }
      }
    }
  }
}

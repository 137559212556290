@import "../../Sass/main";

.chatbotBody {
  background-color: $light;
  @media (min-width: 1100px) {
    .container_message {
      padding: 10px 10px 10px 10px;
      margin: 0 0 10px 20%;
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    .container_message {
      padding: 10px 10px 10px 10px;
      margin: 0 0 10px 0;
      width: 100%;
    }
  }

  .container_message {
    min-height: calc(100vh - 10px);
  }

  .content {
    padding: 0 0 50px 0;

    .project-name {
      font-size: 18px;
      font-weight: normal;
      margin: 20px auto;
      text-align: center;
      width: 90%;
    }

    .titleLogic {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      color: darkred;
      padding: 10px 0px;
    }
  }
}

@import "../../Sass/main";

.navigationArea {
  background-color: $gray-light;
  padding-top: 10px;

  .navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;

    img {
      cursor: pointer;
      height: 40px;
    }

    .buttonArea {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 55px;
      justify-content: flex-start;

      .buttonDefault {
        align-items: center;
        border: 2px solid $color121-light;
        border-radius: 10px;
        color: $color121-light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        height: 40px;
        justify-content: center;
        width: 150px;
      }

      .buttonSelected {
        align-items: center;
        background-color: $color121-light;
        border-radius: 10px;
        color: $light;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        height: 40px;
        justify-content: center;
        width: 150px;
      }

      .triangleDown {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $color121-light;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .triangleHide {
        border-top: 15px solid $light;
        cursor: pointer;
      }
    }
  }

  .totalAnswersArea {
    color: $color121-light;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;

    .textRed {
      color: $danger-color;
    }
  }
}

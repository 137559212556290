@import "../../Sass/main.scss";

.question-header {
  margin: auto auto;
}

.modal-container {
  background: #609ef1;
  border-radius: 10px;
  min-height: 80%;
  padding-bottom: 15px;
  width: 100%;

  .modal-section {
    margin: 10px auto;
    overflow-y: auto;
  }

  .buttons-bottom {
    display: flex;
    margin-top: 20px;

    button {
      border: none;
      border-radius: 5px;
      margin: 0 8px;
      padding: 5px 10px;
    }

    .btn-save {
      color: $color121-light;
      width: 100%;
    }
  }
}
.filter-content {
  align-items: stretch;
  display: flex;
  flex-direction: column;

  .cross-filter {
    align-items: flex-start;
    border-bottom: 1px #fff solid;
    border-top: 1px #fff solid;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;

    div {
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    select {
      border-radius: 4px;
      flex: 1;
      padding: 2px;
      margin-left: 5px;
      outline: none;
    }
  }

  .header-filter {
    border-bottom: 1px #fff solid;
    border-top: 1px #fff solid;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;

    .filter-num {
      background: #fff;
      border-radius: 5px;
      color: $color121-light;
      padding: 5px 10px;
    }

    svg {
      font-size: 20px;
    }
  }

  .modal-content {
    padding: 10px;
    letter-spacing: 1px;

    .label-options {
      align-items: center;
      display: inline-flex;
      margin: 2px;
      padding: 2px;

      & input {
        margin-right: 5px;
      }
    }
  }
}

@import "../../Sass/main";

.paymentStyled {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1280px;
  padding: 15px;

  .card-con {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 2rem;
  }

  .iconGoBack {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .buttonGoBack {
      align-items: center;
      background-color: $color121-light;
      border: none;
      border-radius: 10px;
      color: $light;
      cursor: pointer;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin-left: 1rem;
      padding: 6px 12px;
      width: 95px;
    }
  }

  .img-hazo {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;

    & > img {
      object-fit: contain;
      width: 80px;
    }
  }

  .small-heading {
    margin-top: 2rem;
    text-align: center;
  }

  .c-para {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .card-con {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@import "../../Sass/main";

.compBoxAreaReports {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 100%;
  padding-bottom: 20px;
  width: 100%;

  .boxChartTitle {
    font-weight: bold;
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .chartArea {
    margin: 20px 0;
    min-height: 350px;
  }

  .videowrapper {
    height: 0;
    margin-bottom: 20px;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;
    width: 100%;
  }

  .videowrapper iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .engajamentoButtons {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .buttonSelected {
      background-color: $color121-light;
      border: 2px solid $color121-light;
      border-radius: 5px;
      color: $light;
      cursor: pointer;
      padding: 6px 6px;
      text-align: center;
      width: 60px;
    }

    .buttonNotSelected {
      background-color: $light;
      border: 2px solid $color121-light;
      border-radius: 5px;
      color: $color121-light;
      cursor: pointer;
      padding: 6px 6px;
      text-align: center;
      width: 60px;
    }
  }

  .buttonFilter {
    position: relative;

    img {
      position: absolute;
      width: 18px;
      top: 22px;
      left: 12px;
    }

    button {
      background: transparent;
      margin-top: 10px;
      border: 2px solid $color121-light;
      border-radius: 5px;
      padding: 6px 6px;
      width: 270px;
      color: $color121-light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin-left: 30px;
      }
    }
  }

  .chartBtnFilter {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 15px;
    width: 100%;

    .btnFilterContainer {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      span {
        color: $danger-color;
        position: absolute;
        right: 0;
        top: -0.5rem;
      }
    }

    .btnFilter {
      align-items: center;
      background: $color121-light;
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin: 3px;
      padding: 10px;

      svg {
        color: #fff;
      }
    }
  }
}

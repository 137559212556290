@import "../../Sass/main";

.compChartsAndTable {
  .boxNotAnswer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 250px;
    text-align: center;
  }

  .loadingEngagement {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    justify-content: center;
    width: 700px;

    p {
      color: $Standard_Default;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.notShowingAnswers {
  color: $gray-dark;
  margin-bottom: 10px;
  padding: 0 10px;
  text-align: center;

  span,
  span:hover {
    color: $gray-dark;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
}

.btnSort {
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  margin: 0 3px;
  padding: 2px;
  right: 0;
  top: 8px;
  width: 35px;

  img {
    height: 25px;
    object-fit: contain;
    width: 25px;
  }
}

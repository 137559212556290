@import "../../../Sass/main";

.compModalFilterQuestion {
  hr {
    background-color: $light;
  }

  .titleModal {
    margin: 0;
    text-align: center;
  }

  .modal-header {
    border: none !important;
    padding: 10px 10px 0 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    main {
      align-items: center;
      display: flex;
      flex-direction: column;

      .inputSearch {
        margin-bottom: 15px;
        position: relative;
        padding: 10px 35px;
        width: 100%;

        .input {
          border: none;
          border-radius: 10px;
          color: $background-2;
          height: 40px;
        }

        img {
          position: absolute;
          right: 50px;
          top: 22px;
          width: 15px;
        }
      }

      .listQuestions {
        align-self: flex-start;
        margin-bottom: 10px;
        max-height: 300px;
        overflow-y: auto;
        padding-left: 40px;
        width: 450px;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        .question {
          align-items: center;
          display: flex;
          gap: 10px;
          margin-bottom: 15px;

          input[type="radio"] {
            transform: scale(1.2);
          }

          label {
            display: block;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    footer {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: center;
      padding: 0 35px 15px 35px;

      button {
        background-color: $light;
        border: none;
        border-radius: 5px;
        color: $color121-light;
        padding: 5px 10px;
        width: 100%;
      }
    }
  }
}

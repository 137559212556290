@import "../../Sass/main";

.compDocumentation {
  min-height: 100vh;
  min-width: none;

  a {
    color: $dark !important;
    text-decoration: underline;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }

  #scroll-to-top-btn {
    align-items: center;
    bottom: 20px;
    background-color: $gray-default;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    height: 50px;
    justify-content: center;
    position: fixed;
    right: 20px;
    transition: background-color 0.2s ease;
    width: 50px;

    img {
      height: 30px;
    }
  }

  #scroll-to-top-btn:hover {
    background-color: $gray-dark;
  }

  .documentation {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

    .api-section {
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 30px;
      font-weight: bolder;
      text-align: center;
    }

    h2 {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.4rem;
    }
  }
}

@import "../../Sass/main";

.tooltip {
  z-index: 0 !important;
}

.compShare {
  #printQr {
    cursor: pointer;
    width: 256px;
  }

  .boxLink {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    img {
      cursor: pointer;
      height: 25px;
      width: 25px;
    }

    .cancelButton {
      height: 20px;
      width: 20px;
    }
  }

  .boxMultipleAnswer {
    display: flex;
    gap: 10px;
  }

  .box {
    background-color: $gray-light;
    border-radius: 5px;
    color: $gray-dark;
    margin: 10px 0px 0px 15%;
    padding: 10px 10px 10px 10px;
    width: fit-content;

    .linkToShare,
    .linkToShare:hover {
      color: $gray-dark;
      cursor: pointer;
      text-decoration: underline;
    }

    input {
      border: none;
      border-radius: 5px;
      margin: 0 5px;
      padding: 2px 10px;
    }
  }

  .embed-box {
    background: #fff;
    padding: 0;
    padding-top: 10px;

    h4 {
      font-size: 18px;
      font-weight: 800;
      text-align: center;
    }

    .preview {
      font-size: 15px;
    }
  }

  .btn-embed {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    margin: 10px 0 5px 0;

    button {
      background: $color121-light;
      color: #fff;
      border: none;
      font-size: 14px;
      padding: 3px 5px;
    }

    .btn-right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .btn-left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .settings-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .box-image {
    max-height: 100px;
    display: block;
    margin: 5px auto;
  }

  .embed-link {
    align-items: flex-end;
    background: #609ef161;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    margin-bottom: 5px;

    .button-copy {
      background: $color121-light;
      border: none;
      border-radius: 5px;
      color: $light;
      padding: 4px 10px;
    }
  }

  .boxShare {
    background-color: $color121-light;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px 0px 15%;
    max-width: 600px;
    padding: 10px 10px 10px 10px;
    width: 85%;

    .optionShare {
      align-items: center;
      display: flex;
      flex-basis: 33.3%;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0 20px 0;
      text-decoration: none;

      a {
        display: block;
      }

      img {
        cursor: pointer;
        height: 46px;
        width: 46px;
      }

      p {
        color: $light;
        cursor: pointer;
        margin: 0;
      }

      .subtitle {
        font-size: 10px;
      }
    }

    button {
      background: transparent;
      border: none;
    }
  }

  .contentShare {
    padding: 30px 0;

    .firstMessageArea {
      align-items: center;
      display: flex;
      gap: 10px;
      margin: 10px 0px 0px 15%;

      .hazo-image {
        width: 50px;
      }

      .link-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .box {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 1100px) {
    .container_message {
      margin: 0 0 10px 20%;
      padding: 10px 10px 10px 10px;
      width: 60%;
    }
  }

  @media (max-width: 720px) {
    .boxLink {
      justify-content: center;
    }
  }

  @media (max-width: 1100px) {
    .container_message {
      padding: 10px 10px 10px 10px;
      margin: 0 0 10px 0;
      width: 100%;
    }
  }
}

@import "../../Sass/main";

.CompPageNotFound {
  align-items: center;
  background: $color121-light;
  color: $light;
  display: flex;
  flex-direction: column;
  font-family: $font-login;
  height: 100vh;
  justify-content: center;
  text-align: center;

  img {
    margin-top: -100px;
  }

  .contentArea {
    margin-top: -100px;

    .fontBig {
      font-size: 40px;
    }

    .fontMedium {
      font-size: 25px;
    }
  }
}

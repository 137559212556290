@import "../../Sass/main";

.pageMessages {
  background-color: $color121-light;
  min-height: calc(100vh + 10px);

  @media (min-width: 1100px) {
    .contentArea {
      margin: 0 0 0 20%;
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    .contentArea {
      margin: 0;
      width: 100%;
    }
  }

  .contentArea {
    background-color: $light;
    min-height: calc(100vh);
  }

  .headerMessages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $light;

    .line {
      border-bottom: solid 1px $gray-light;
      width: 100%;
    }

    .close {
      width: 40px;
      height: 5px;
      margin-top: 10px;
      align-self: flex-end;
    }

    .contentTitle {
      .title {
        color: $color121-light;
        font-weight: bold;
        font-size: 20px;
      }

      img {
        width: 55px;
        height: 70px;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      margin-top: 10px;

      button {
        background-color: transparent;
        border: none;
        font-size: 17px;
        font-weight: bold;
      }

      .containerOn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 3px $color121-light;

        button {
          color: $color121-light;
        }
      }

      .containerOff {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }

      .buttonOn {
        color: $color121-light;
        border-bottom: solid 3px $color121-light;
      }

      .buttonOff {
        color: #c4c4c4;
      }

      #badge {
        background-color: red;
        color: $light;
        border-radius: 100%;
        padding: 2px 7px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .containerMessages {
    .messages {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-top: 10px;
    }

    .messagesOn {
      background-color: #f1f1f1;
      p,
      span {
        color: #3264a6;
      }
    }

    .messagesOff {
      p,
      span {
        color: $color121-light;
      }
    }

    .buttonSave,
    .dateInfo {
      align-self: flex-end;
    }

    .buttonSave {
      background-color: transparent;
      border: none;
      margin-right: -8px;
    }

    .title {
      font-weight: bold;
      font-size: 17px;
    }

    .description,
    .title,
    .dateInfo {
      margin-left: 25px;
      margin-right: 25px;
    }

    .link {
      align-self: start;
      background-color: transparent;
      border: none;
      color: #007bff;
      margin-left: 20px;
      text-decoration: underline;
    }

    .dateInfo {
      margin-top: 5px;
    }

    .line {
      margin-top: 15px;
      border-bottom: solid 1px $color121-light;
      width: 100%;
    }

    .warning {
      margin-top: 20px;
      text-align: center;
      color: #c4c4c4;
    }
  }
}

@import "../../../Sass/main";

.compModalAnswers {
  .modal-header {
    border: none !important;
    padding: 10px 10px 0 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    .content {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 10px 10px 10px;

      .boxKindOfAnswer {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
        height: 100px;
        justify-content: flex-start;
        margin: 15px 0;

        img {
          height: 40px;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 40px;
        }

        .KOAtitle {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 0;
          text-align: center;
        }

        .KOAsubtitle {
          font-size: 11px;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}

@import "../../../Sass/main.scss";

textarea {
  @include input-hazo();
  height: 84px;
}

.boxCheck {
  align-items: center;
  background-color: $light;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 6px;
  width: 30px;
}

.boxChecked {
  background-color: $color121-light;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.exampleText {
  font-size: 8px;
  margin-left: 5px;
}

.inputMaxCaracteres {
  border: none;
  border-radius: 5px;
  color: $gray-dark;
  height: 30px;
  margin: 0 5px;
  text-align: center;
  width: 80px;
}

.PreferNotAnwser {
  display: flex;

  div {
    font-size: 12px;
    margin-right: 10px;
  }
}

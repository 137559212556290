@import "../abstracts/variables";
@import "../base/typography";

@mixin input() {
  border: none;
  border-radius: 4px;
  color: $light;
  font: bold, 1rem, $text-input;
  height: 38px;
  margin-top: 1rem;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  width: 100%;

  &::placeholder {
    font-weight: 400;
    text-align: center;
  }
}

@mixin input-hazo() {
  background-color: $light;
  border: none;
  border-radius: 5px;
  color: $gray-dark;

  &:focus {
    background-color: $light;
    color: $gray-dark;
  }
}

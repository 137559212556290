@import "../../Sass/main";

@media (min-width: 1100px) {
  .compHeaderProjectsPage {
    margin: 0 0 0 20%;
    width: 60%;
  }
}

@media (max-width: 1100px) {
  .compHeaderProjectsPage {
    margin: 0 0 0 0;
    width: 100%;
  }
}

.container {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  height: 65px;
  padding: 0 10px;

  .buttonMessages {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .notification {
      margin-right: 5px;
      width: 30px;
    }

    #badgeMessage {
      background-color: red;
      color: transparent;
      border-radius: 100%;
      position: absolute;
      padding: 2px 5px;
      width: 12px;
      height: 12px;
      left: 15px;
      top: 1px;
    }

    #badgeMessageHide {
      display: none;
    }

    #triangle {
      position: absolute;
      top: 30px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 20px solid $light;
      margin-top: 7px;
      margin-right: 5px;
    }

    #triangleHide {
      display: none;
    }
  }

  .buttonPremium {
    align-items: center;
    background-color: $light;
    border: none;
    border-radius: 10px;
    color: $color121-light;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 6px 12px;
    width: 95px;
  }

  .hazotext {
    align-items: center;
    color: $light;
    display: flex;
    flex-grow: 4;
    font-family: $font-logo-hazo;
    font-size: 25px;
    height: 25px;
    justify-content: center;
    margin-left: 20px;
  }

  .buttonArea {
    width: 95px;

    .profileCircle {
      align-items: center;
      border-radius: 50%;
      background-color: $light;
      color: $color121-light;
      cursor: pointer;
      display: flex;
      font-size: 30px;
      height: 45px;
      justify-content: center;
      margin: 10px 0;
      right: 0 !important;
      width: 45px;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

@import "../../../Sass/main";

.ModalConfimationProjectDelete {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 1051;
  width: 100vw;

  .buttonArea {
    align-items: center;
    flex-direction: row;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;

    .buttonNo {
      align-items: center;
      background-color: $light;
      border-radius: 20px;
      color: $color121-light;
      cursor: pointer;
      display: flex;
      font-family: $font-login-bold;
      height: 40px;
      justify-content: center;
      width: 120px;
    }

    .buttonExclude {
      align-items: center;
      background-color: $gray-dark-less;
      border-radius: 20px;
      color: $light;
      cursor: pointer;
      display: flex;
      font-family: $font-login-bold;
      height: 40px;
      justify-content: center;
      width: 120px;
    }
  }

  .divTitle {
    font-family: $font-login-bold;
    width: 70%;
  }

  .question {
    font-family: $font-login;
    margin-top: -40px;
  }

  .hazotext {
    color: $light;
    font-family: $font-logo-hazo;
    font-size: 30px;
    margin: -60px 0 50px 0;
  }

  img {
    margin-top: -50px;
  }

  #triangulo-para-baixo {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid $light;
    height: 0;
    margin: 10px 0 0 -7px;
    width: 0;
  }
}

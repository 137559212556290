@import "../../../Sass/main";

.ModalConfimationProjectDelete {
  align-items: center;
  background-color: $color121-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  padding: 15px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1051;

  p {
    color: $light;
    font-size: 20px;
    margin: -30px 20px 20px 20px;
    text-align: justify;
  }

  .iconGoBack {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .arrowLeft {
      cursor: pointer;
      height: 30px;
      margin-left: 10px;
      width: 30px;
    }
  }

  .modalButtonArea {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .button {
      background-color: $light;
      border: 2px solid $light;
      border-radius: 50px;
      color: $color121-light;
      cursor: pointer;
      font-weight: bold;
      padding: 6px 12px;
      width: 200px;
    }

    .onlyUnderline {
      align-items: center;
      background: none;
      border: none;
      color: $light;
      cursor: pointer;
      display: flex;
      font-family: $font-login;
      justify-content: center;
      height: 40px;
      text-decoration: underline;
    }
  }
}

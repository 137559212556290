@import "../../Sass/main";

.squareProjectBox {
  align-items: center;
  background-color: $light;
  border: 2px solid $color121-light;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 80px;
  margin: 0 15px;
  padding: 5px;
  width: calc(100% - 30px);

  .line {
    border-left: 2px solid $color121-light;
    height: 60px;
    width: 2px;
  }

  .qtdAnswers {
    align-items: center;
    color: $color121-light;
    display: flex;
    font-size: 20px;
    justify-content: center;
    min-width: 85px;

    .profileIcon {
      height: 15px;
      margin: 0 5px 2px 0;
      width: 15px;
    }
  }

  .title {
    color: $color121-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px;
    width: 100%;

    h4 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }

    span {
      font-size: 12px;
      margin: 0;
    }
  }
}

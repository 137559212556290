@import "../../Sass/main";

.CompWelcome {
  align-items: center;
  background: $color121-light;
  color: $light;
  display: flex;
  flex-direction: column;
  font-family: $font-login;
  justify-content: center;
  min-height: 100vh;
  padding: min(5%) 10px min(5%) 10px;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px 50px 10px;

    #triangulo-para-baixo {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid $light;
      height: 0;
      margin-top: 10px;
      width: 0;
    }

    .buttonSignup {
      border: 2px solid $light;
      border-radius: 30px;
      font-family: $font-login-bold;
      margin-bottom: 20px;
      padding: 10px 0;
      text-align: center;
      width: 100%;
    }

    .buttonSignin {
      background-color: $light;
      border-radius: 30px;
      color: $color121-light;
      font-family: $font-login-bold;
      padding: 10px 0;
      text-align: center;
      width: 100%;
    }

    .buttonHover,
    .buttonHover:hover {
      cursor: pointer;
    }

    .hazoImage {
      margin-top: -50px;
    }

    .hazotext {
      color: $light;
      font-family: $font-logo-hazo;
      font-size: 30px;
      margin: -60px 0 50px 0;
    }

    .textArea {
      text-align: center;
    }
  }

  .fontBold {
    font-family: $font-login-bold;
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footerFont {
      font-family: $font-121-bold;
      font-size: 12px;
    }

    .footerImage {
      width: 35px;
    }
  }
}

@import "../../Sass/main.scss";

.tooltip-inner.tooltip-erro {
  background-color: $danger-color;
}

.arrow::before {
  border-top-color: $danger-color !important;
  border-bottom-color: $danger-color !important;
}

.sheets-integration-page {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  width: 100%;
  background-color: #fff;
  font-family: $font-login;

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    .button-save {
      background-color: $color121-light;
      color: $light;
      border: 2px solid $light;
      border-radius: 50px;
      cursor: pointer;
      font-weight: bold;
      margin: 5px 0;
      padding: 6px 12px;
      width: 150px;
    }

    .onlyUnderline {
      align-items: center;
      background: none;
      border: none;
      color: $color121-light;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      text-decoration: underline;
    }
  }

  .integration-header {
    display: flex;
    width: 90%;
    align-self: center;
    /* topo | horizontal | inferior */
    margin: 120px auto 10px;
  }

  .integration-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    width: 100%;
  }

  .integration-texts {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .input-container {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    /* topo | horizontal | inferior */
    margin: 0 20px;

    .input-box {
      background: #f1f1f1;
      border: 1px solid #afaeae;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
    }

    .subtitle-texts {
      margin-top: 30px;
    }

    .onlyUnderline {
      align-items: center;
      background: none;
      border: none;
      color: $color121-light;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      text-decoration: underline;
    }
  }

  .message-box {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
    /* topo | horizontal | inferior */
    margin: 20px;
  }
}

@import "../../Sass/main";

.CompChangesLog {
  align-items: center;
  background: $color121-light;
  color: $light;
  display: flex;
  flex-direction: column;
  font-family: $font-login;
  justify-content: center;
  min-height: 100vh;
  padding: min(5%) 10px min(5%) 10px;

  h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 10px 50px 10px;

    p {
      margin: 0;
    }

    .titleChangesLog {
      color: $light;
      font-weight: bold;
      margin: 30px 0 10px 0;
      text-decoration: underline;
    }
  }

  .fontBold {
    font-family: $font-login-bold;
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footerFont {
      font-size: 12px;
      font-family: $font-121-bold;
    }

    .footerImage {
      width: 35px;
    }
  }

  .goBackButton {
    align-items: center;
    background-color: $light;
    border-radius: 10px;
    color: $color121-light;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: 6px 12px;
    width: 95px;
  }
}

@import "../../../Sass/main";

.compModalRenameDuplicateProject {
  .modal-header {
    border: none !important;
    padding: 10px 10px 0 10px;
  }

  .modal-content {
    background-color: $color121-light !important;
    border: none;
    color: $light;

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 10px;

      .buttonArea {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        padding-top: 20px;

        .buttonSelected {
          background-color: $color121-light;
          border: 2px solid $color121-light;
          border-radius: 5px;
          color: $light;
          cursor: pointer;
          padding: 6px 12px;
          text-align: center;
          width: 120px;
        }

        .buttonNotSelected {
          background-color: $light;
          border: 2px solid $color121-light;
          border-radius: 5px;
          color: $color121-light;
          cursor: pointer;
          padding: 6px 12px;
          text-align: center;
          width: 120px;
        }
      }

      .divTitle {
        margin-top: -20px;
      }

      .hrTitle {
        background-color: white;
        margin: 15px 0 !important;
        width: 90%;
      }

      .inputTitle {
        background-color: $light;
        border: none;
        border-radius: 5px;
        padding: 6px 12px;
        margin: 0 5px;
        width: calc(100% - 20px);
      }
    }
  }
}

@import "../../../Sass/main";

.compInsertVideo {
  .questionArea {
    width: calc(90% - 10px);
  }

  textarea {
    @include input-hazo();
    height: 84px;
  }

  .inputBorderRed {
    border: 1px solid red;
  }
}
